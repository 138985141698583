import React, {useState, useEffect} from 'react'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import {Link, useNavigate} from 'react-router-dom'

import {PrimaryButton} from 'components/Buttons'
import useUserStore from 'store/UserStore'

const initial_state = {
  identity: '',
  userErrors: {},
  otp: '',
  isProcessing: false,
}

const ForgotPassword = () => {
  const navigate = useNavigate()
  const {
    errors: userErrors,
    codeSent,
    isProcessing,
    resetUserFlagsRequest,
    forgotPasswordRequest,
  } = useUserStore()

  const [state, setState] = useState(initial_state)

  useEffect(() => {
    if (codeSent) {
      resetUserFlagsRequest()
      navigate('/reset_password')
    }
  }, [codeSent, navigate, resetUserFlagsRequest])

  useEffect(() => {
    if (userErrors) {
      setState(s => ({...s, userErrors: userErrors}))
    }
  }, [userErrors])

  const onFieldChange = (fieldName, event) => {
    setState(s => ({
      ...s,
      [fieldName]: event.target.value,
      [`${fieldName}userErrors`]: null,
    }))
  }

  const handleForgotPassword = () => {
    const {identity} = state
    setState(s => ({
      ...s,
      isProcessing: true,
    }))

    if (identity === '') {
      setState(s => ({
        ...s,
        identityError: 'Please provide a valid registered email.',
        isProcessing: false,
      }))
    } else {
      forgotPasswordRequest(identity)
    }
  }

  const errorMessageFor = fieldName => {
    if (userErrors && userErrors[fieldName]) {
      return userErrors[fieldName]
    }
  }
  return (
    <div className="login-container">
      <div className="container">
        <Card className="cardContainer">
          <h3 className="center-align">Forgot Password</h3>
          <TextField
            fullWidth
            className="text-field"
            id="email"
            type="text"
            label="Email"
            margin="normal"
            onChange={event => onFieldChange('identity', event)}
            onKeyPress={event => {
              if (event.key === 'Enter' || event.keyCode === 13) {
                handleForgotPassword()
              }
            }}
          />
          <span className="error">{errorMessageFor('mobile')}</span>
          <div className="mt40">
            <PrimaryButton
              color="primary"
              buttonName={
                isProcessing ? 'Please Wait' : 'Request Password Reset Link'
              }
              className="leftIcon-btn pswrd-btn"
              disabled={!!isProcessing}
              handleButtonClick={() => handleForgotPassword()}
            />
          </div>
          <div className="terms-n-policy">
            <Link className="underline" to="/login">
              Return to Login page
            </Link>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default ForgotPassword
